<template>
  <div class="tabbar">
    <div class="tabbar_img">
      <div class="left">
        <img src="@/assets/logo_2.png" />
      </div>
      <div @click="drawer = !drawer" class="right">
        <img src="~@/assets/img/m/tabbar/menu.png" />
      </div>
    </div>
    <el-drawer :visible.sync="drawer" direction="ttb" :modal="false" :withHeader="false">
      <el-collapse v-show="drawer" class="menu">
        <template v-for="(item, index) in tabbarList">
          <template v-if="item.children ? false : true">
            <div class="menu_item" @click="tabbarChange(item, index)" :key="item.id">
              {{ item.name }}
            </div>
          </template>
          <template v-else>
            <el-collapse-item :key="item.id">
              <template slot="title">
                {{ item.name }}
              </template>
              <div v-for="(item1, index) in item.children" :key="item1.name" class="menu_toitem" @click="tabbarChange(item1, index)">
                {{ item1.name }}
              </div>
            </el-collapse-item>
          </template>
        </template>
      </el-collapse>
    </el-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      direction: 'ttb',
      tabbarList: [
        { name: '特征', path: 'home' },
        {
          name: '产品',
          path: '',
          children: [
            { name: '产品', path: 'product' },
            { name: '产品体系', path: 'system' },
            { name: '下载', path: 'download' }
          ]
        },
        {
          name: '方案',
          path: '',
          children: [
            {
              name: '企业准入方案',
              path: 'enterprise'
            } /**, {	name: "酒店实名上网方案",	path: "example"},
					{name: "政务免费实名入网",path: "interaction"}, {name: "连锁机构统一准入方案",path: "interaction"}*/
          ]
        }
      ]
    }
  },
  methods: {
    handleClose(done) {
      done()
    },
    routerChange(item) {
      this.$router.push(item.path)
      this.drawer = false
    },
    tabbarChange(item, index) {
      this.drawer = false
      this.$router.push(item.path)
    }
  }
}
</script>

<style lang="scss" scoped>
.tabbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: white;
}
.tabbar_img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 31.914893rem;
  height: 5.106382rem;
  padding: 0 1.276595rem;
  margin: 0 auto;
  box-sizing: border-box;
  .left {
    img {
      width: 10.212765rem;
      height: 2.978723rem;
    }
  }
  .right {
    img {
      width: 2.553191rem;
      height: 1.914893rem;
    }
  }
}
.menu {
  padding: 0 1.276595rem;
  border-bottom: none !important;
}
.menu_item {
  color: #333;
  font-size: 1.361702rem;
  height: 4.255319rem;
  line-height: 4.255319rem;
}
.menu_toitem {
  font-size: 1.191489rem;
  color: #666;
  height: 3.404255rem;
  line-height: 3.404255rem;
}
/deep/ .el-drawer__wrapper {
  top: 5.106382rem !important;
}
/deep/ .el-collapse-item__header {
  border-bottom: none !important;
}
/deep/ .el-drawer {
  height: auto !important;
}
/deep/ .el-collapse-item__header {
  color: #333;
  font-size: 1.361702rem;
  height: 4.255319rem;
  line-height: 4.255319rem;
}
/deep/ .el-collapse-item__content {
  padding: 0 !important;
}
</style>
