<template>
	<div class="totop">
		<div class="show"  :class="isShow?'isShow':''">
			<div @click="isShow=!isShow"><img src="@/assets/img/common/weibo01.png" ></div>
			<div @click="isShow=!isShow">
				<img src="@/assets/img/common/us.png" >
				<span>在</span>
				<span>线</span>
				<span>咨</span>
				<span>询</span>
			</div>
			<div @click="backTop">
				<img src="@/assets/img/common/top.png" >
			</div>
		</div>
		<div class="telephone" :class="isShow?'active':''">
			<div class="telephone_name">
				<div class="name">在线咨询 <span class="iconfont icon-shanchu"  @click="isShow=!isShow"></span></div>
				<div class="phone">
					<div class="text">商务洽谈</div>
					<div class="imgs" @click="call(15010366164)"><img src="@/assets/img/common/telephone.png"><span>150-1036-6164</span></div>
					<div class="imgs" @click="call(18101310911)"><img src="@/assets/img/common/telephone.png"><span>181-0131-0911</span></div>
					<div class="text">技术支持</div>
					<div class="imgs"@click="call(18932451909)" ><img src="@/assets/img/common/telephone.png"><span>189-3245-1909</span></div>
				</div>
				
			</div>
			<div class="qq"><span>微博/QQ</span><img src="@/assets/img/common/weibo.png"> <img src="@/assets/img/common/qq.png" @click="qq"></div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				isShow:true
			}
		},
		methods:{
			//返回顶部
			backTop() {
			 let top = document.documentElement.scrollTop || document.body.scrollTop
			 // 实现滚动效果
			 const timeTop = setInterval(() => {
			   document.body.scrollTop = document.documentElement.scrollTop = top -= 60
			   if (top <= 0) {
			     clearInterval(timeTop)
			   }
			 }, 10)
			},
			qq(){
				window.open("mqqwpa://im/chat?chat_type=wpa&uin=1666089717&version=1&src_type=web&web_src=lvlingseeds.com");
			},
			call(tel){
				window.location.href ="tel://"+ tel;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.isShow{
		right: -7.894736rem!important
	}
	.active{
		right: 0.263157rem!important;
	}
	.totop{
		position: fixed;
		z-index: 999;
		bottom: 10%;
		right: 0;
		height: 18.421052rem;
		.show{
			transition: all .5s ease;
			// width: 2.631578rem;
			width: 4rem;
			position: absolute;
			right: 0.526315rem;
			bottom: 2.947368rem;
			div{
				&:first-child{
					width: 4rem;
					height: 4rem;
					background-color: #59b839;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-bottom: 0.526315rem;
					border-radius: 0.394736rem;
					img{
						max-width: 75%;
					}
				}
				&:nth-child(2){
					// width: 2.631578rem;
					width: 4rem;
					// height: 7.894736rem;
					height: 15rem;
					background-color: #59b839;
					border-radius: 0.394736rem;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					margin-bottom: 1.052631rem;
					img{
						max-width: 100%;
						display: block;
						margin: 0 auto;
					}
					span{
						font-size: 1.5rem;
						color: white;
					}
				}
				&:last-child{
					position: absolute;
					z-index: 9999;
					width: 4rem;
					height: 4rem;
					border-radius: 50%;
					background-color: #59b839;
					overflow: hidden;
					img{
						display: block;
						margin: 0 auto;
						margin-top: 20%;
						max-width: 60%;
					}
				}
			}
		}
		
		.telephone{
			transition: all .5s ease;
			position: absolute;
			right: -20rem;
			// top: 0.052631rem;
			bottom: 3.5rem;
			// width: 0px;
			// display: none;
			width: 15rem;
			height: 20rem;
				.telephone_name{
					height: 20rem;
					width: 15rem;
					margin: 0;
					background-color: white;
					border-radius: 0.212765rem;
					display: flex;
					flex-direction: column;
					.name{
						height:3rem;
						padding-left: 1.315789rem;
						line-height: 3rem;
						font-size: 1.5rem;
						background-color: #59b839;
						border-radius: 0.212765rem;
						color: #FFFFFF;
						span{
							font-size: 2rem;
							float: right;
							margin-right: 1.315789rem;
						}
					}
					.phone{
						flex: 1;
						border: 1px solid #EEEEEE;
						border-top: none;
					}
					.text{
						font-size: 1.5rem;
						color: #333;
						padding-left: 0.789473rem;
						margin: 0.526315rem 0 0.394736rem;
					}
					.imgs{
						padding-left: 0.789473rem;
						display: flex;
						align-items: center;
						margin-bottom: 0.526315rem;
						font-size: 1.2rem;
						color: #444;
						img{
							width: 3rem;
							height: 3.5rem;
							margin-right: 0.526315rem;
						}
					}
				}
				.qq{
					border-radius: 0.263157rem;
					// width: 10.526315rem;
					margin: 0.789473rem auto 0;
					height: 4rem;
					background-color: #59b839;
					box-shadow: 0px 2px 5px 0px #c8c8c8 ;
					font-size: 1rem;
					color: white;
					line-height: 2.631578rem;
					padding: 0 1.315789rem;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					justify-content: space-between;
					img{
						height: 80%;
						&:last-child{
							height: 50%;
						}
					}
				}
		}
	}
</style>
