<template>
  <div id="m">
    <Tabbar v-if="show"></Tabbar>
    <router-view />
    <!-- <ToTop></ToTop> -->
  </div>
</template>

<script>
window.onresize = function(e) {
  let isMobile = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  if (!isMobile) {
    location.reload()
  }
}
import Tabbar from '@/components/m/tabbar/tabbar.vue'
import ToTop from '@/components/m/totop/index.vue'
export default {
  components: {
    Tabbar,
    ToTop
  },
  data(){
    return{
      show:true
    }
  },
  mounted() {
    // 获取当前页面的路由路径
    const currentRoute = this.$route.path;
    if (currentRoute.includes('authOpenId')) {
      this.show = false; // 如果包含authOpenId，则将show值设为false
      this.$forceUpdate(); 
    }
  },
}
</script>

<style lang="scss" scoped>
@import url('~@/style/m.css');
#m {
  width: 100%;
  // width: 31.914893rem;
  // margin: 0 auto;
  overflow: hidden;
  padding-top: 5.106382rem;
}
</style>
